import styled from 'styled-components';


export const AlignItemsLeft = styled.div`
  width: 441px;
  height: 208px;
  margin-top: 30px;
`
  ;

export const Buttons = styled.div`
  justify-content: space-between;
  margin-top: 20px;
`;


export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  font-family: Carbona, Nunito Sans, sans-serif !important;
`;
