import { ACTIVATE_WHATSAPP_ROUTE } from '../../constants/ApiRoutes';
import {
  PG_ACTIVE_WHATSAPP_BUTTON,
  PG_EMBEDDED_SIGNUP_STATUS,
  PG_REOPEN_WPP_POPUP,
  PG_WHATSAPP_ACTIVATED,
  PG_WHATSAPP_ACTIVATION_ERROR,
} from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import useGraphApi from '../../hooks/useGraphApi';
import useTrack from '../../hooks/useTrack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { createToastError, createToastSuccess } from '../../services/Toast';
import { PhoneNumber, WhatsAppInfo } from '../../typings/WhatsAppActivation';
import { isNumber } from '../../utils';
import { Container, ContainerActions, ContainerFormLabel, Form, PinContainer } from './style';

const WhatsAppActivationStep: FC = () => {
  const { apiService } = useAppContext();
  const { pack, activateWppChannel } = usePackContext();
  const { getWabas, launchWhatsAppSignup } = useGraphApi();
  const [whatsAppInfo, setWhatsAppInfo] = useState<WhatsAppInfo>();
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState({ value: '', showValue: false });
  const { tracking } = useTrack();

  const handleChangePin = (e: any) => setPin({ ...pin, value: e.target.value });
  const handleShowPin = () => setPin({ ...pin, showValue: !pin.showValue });
  const handleKeyPressPin = (e: any) => {
    if (!isNumber(e.key)) {
      e.preventDefault();
    }
  };
  const handleFormIsValid = () => {
    return whatsAppInfo?.selectedNumber?.displayPhoneNumber != '' && isNumber(pin.value) && pin.value.length == 6;
  };

  const trackAndOpenEmbeddedSignUp = () => {
    tracking(PG_REOPEN_WPP_POPUP);
    launchWhatsAppSignup(fetchUserInformations);
  };

  const fetchUserInformations = async (token: string) => {
    const { wabas, applicationName } = await getWabas(token);
    const phoneNumbers = wabas.map((waba: any) => waba.phoneNumbers).reduce((a: any, b: any) => a.concat(b));
    const uniquePhoneNumbers = phoneNumbers
      .filter((value: any, index: any, array: any) => array.indexOf(value) === index)
      .sort();

    tracking(PG_EMBEDDED_SIGNUP_STATUS, { status: !!token ? 'Sucesso' : 'Sem sucesso' });

    setWhatsAppInfo((prevWppInfo: any) => ({
      ...prevWppInfo,
      inputToken: token,
      phoneNumberOptions: uniquePhoneNumbers,
      applicationName: applicationName,
      wabas: wabas,
    }));
  };

  const activateWhatsApp = async () => {
    tracking(PG_ACTIVE_WHATSAPP_BUTTON);
    setIsLoading(true);
    apiService
      .post(ACTIVATE_WHATSAPP_ROUTE, {
        shortName: pack.routerData.shortName,
        phoneNumberId: whatsAppInfo?.selectedNumber?.id,
        wabaId: whatsAppInfo?.selectedNumber?.wabaId,
        pin: pin?.value,
      })
      .then(() => {
        tracking(PG_WHATSAPP_ACTIVATED);
        activateWppChannel();
        createToastSuccess('Sucesso!', 'O canal foi ativado :-)');
      })
      .catch((error: any) => {
        if (error.response?.data?.Error?.ErrorCode === 76 || error.response?.data?.Error?.ErrorCode === 77) {
          tracking(PG_WHATSAPP_ACTIVATION_ERROR, { error: 'Hubspot' });
        } else {
          tracking(PG_WHATSAPP_ACTIVATION_ERROR, { error: 'API' });
        }

        createToastError(
          'Erro na ativação do WhatsApp',
          error.response?.data?.Error?.Message || 'Tente novamente mais tarde',
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleSelectedNumber = useCallback(
    (phoneNumber: PhoneNumber) => {
      if (whatsAppInfo?.wabas) {
        whatsAppInfo?.wabas.forEach((waba: any) => {
          const wabaFromNumber = waba.phoneNumbers.find(
            (number: PhoneNumber) => number.displayPhoneNumber == phoneNumber.displayPhoneNumber,
          );
          if (wabaFromNumber) {
            phoneNumber.wabaId = waba.id;
          }
        });
      }

      setWhatsAppInfo((whatsAppInfo: any) => ({
        ...whatsAppInfo,
        selectedNumber: {
          displayPhoneNumber: phoneNumber.displayPhoneNumber.replace('+', '').replace('-', '').replaceAll(' ', ''),
          id: phoneNumber.id,
          wabaId: phoneNumber.wabaId,
        },
      }));
    },
    [whatsAppInfo?.wabas],
  );

  useEffect(() => {
    const selectListener = document.querySelector('bds-select');

    selectListener?.addEventListener('optionSelected', (event: any) => {
      handleSelectedNumber(JSON.parse(event.detail.value) as PhoneNumber);
    });

    return () =>
      selectListener?.removeEventListener('optionSelected', (event: any) => {
        handleSelectedNumber(JSON.parse(event.detail.value) as PhoneNumber);
      });
  }, [handleSelectedNumber]);

  useEffect(() => {
    launchWhatsAppSignup(fetchUserInformations);
  }, []);

  return whatsAppInfo ? (
    <Container>
      <Form>
        <bds-typo variant="fs-32" bold="bold">
          Ativar WhatsApp
        </bds-typo>
        <bds-typo>Selecione o número que deseja ativar</bds-typo>
        <bds-select placeholder="Selecione um número..." id="phoneNumber" label="Número do WhatsApp" icon="smartphone">
          {whatsAppInfo?.phoneNumberOptions?.map((phoneNumber: PhoneNumber, index: any) => {
            return (
              <bds-select-option value={JSON.stringify(phoneNumber)} key={index}>
                {phoneNumber.displayPhoneNumber}
              </bds-select-option>
            );
          })}
        </bds-select>
        <ContainerFormLabel>
          <label>
            Cadastre um <b>PIN</b>.
          </label>
          <bds-tooltip
            position="right-center"
            tooltip-text="PIN é uma senha de 6 dígitos criada para aumentar a segurança da sua conta do WhatsApp."
          >
            <bds-icon
              color="black"
              name="info"
              size="x-small"
              theme="solid"
              aria-label="Ícone de informações"
            ></bds-icon>
          </bds-tooltip>
        </ContainerFormLabel>
        <PinContainer>
          <bds-input
            type={pin.showValue ? 'text' : 'password'}
            id="pin"
            placeholder="Digite um PIN de 6 dígitos..."
            icon="lock"
            label="PIN"
            required
            minlength-error-message="O campo requer 6 dígitos."
            requiredErrorMessage="Campo obrigatório."
            maxlength={6}
            minlength={6}
            onInput={handleChangePin}
            onPaste={(e: { preventDefault: () => void }) => e.preventDefault()}
            onKeyPress={handleKeyPressPin}
          />
          <bds-icon
            name={pin.showValue ? 'eye-open' : 'eye-closed'}
            theme="outline"
            size="small"
            onClick={handleShowPin}
          />
        </PinContainer>
        <ContainerActions>
          <bds-button
            id="activateNumber"
            variant="primary"
            disabled={!handleFormIsValid()}
            arrow={true}
            onClick={activateWhatsApp}
            bds-loading={isLoading}
          >
            Ativar
          </bds-button>
        </ContainerActions>
      </Form>
    </Container>
  ) : (
    <Container>
      <bds-typo>Clique aqui para iniciar o processo, caso nenhum pop up tenha aberto.</bds-typo>
      <bds-button onClick={() => trackAndOpenEmbeddedSignUp()}>Iniciar ativação WhatsApp!</bds-button>
    </Container>
  );
};

export default WhatsAppActivationStep;
