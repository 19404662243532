import Logos from '../../assets';
import ChannelCard from '../../components/ChannelCard';
import WhatsAppActivationStep from '../../components/WhatsAppActivationStep';
import { BLIP_CHAT_CONFIGURATION } from '../../constants/ApiRoutes';
import { ModuleIds } from '../../constants/ModuleIds';
import { PG_ACTIVATIONBUTTON_WPP, PG_TEST_BLIP_CHAT } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React, { FC, useState } from 'react';
import { createToastError } from '../../services/Toast';
import { HideOrShowContainer } from '../../styles';
import { BlipChatConfiguration } from '../../typings/BlipChat';
import { ModuleComponents } from '../../typings/ModuleComponents';
import { hideOrShowComponentInCss } from '../../utils';
import { Container } from './style';

const ChannelActivation: FC<ModuleComponents> = ({ activeModule }) => {
  const [activateWhatsApp, setActivateWhatsApp] = useState(false);
  const [blipChatConfig, setBlipChatConfig] = useState<BlipChatConfiguration>();
  const { pack } = usePackContext();
  const { settings, apiService } = useAppContext();
  const { tracking } = useTrack();

  const fetchBlipChatConfiguration = async () => {
    try {
      const url = `${BLIP_CHAT_CONFIGURATION}/${pack.routerData.shortName}`;
      const configuration = await apiService.get<BlipChatConfiguration>(url);
      setBlipChatConfig(configuration);
      return configuration;
    } catch (error) {
      createToastError('Falha ao obter as configurações do Blip Chat', 'Por favor, tente daqui alguns minutos.');
    }
  };

  const openBlipChatPopUp = async () => {
    tracking(PG_TEST_BLIP_CHAT);
    const blipChatConfiguration = blipChatConfig ?? (await fetchBlipChatConfiguration());
    const urlbase = `${settings.Environment == 'staging' ? 'hmg-' : ''}chat.blip.ai/`;
    const link = `https://${blipChatConfiguration?.tenantId}.${urlbase}?appKey=${blipChatConfiguration?.appKey}`;

    const width = 400;
    const height = 700;

    const left = window.screenLeft + window.innerWidth / 2 - width / 2;
    const top = window.screenTop + window.innerHeight / 2 - height / 2;

    window.open(link, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
  };

  const handleActivateWhatsApp = () => {
    tracking(PG_ACTIVATIONBUTTON_WPP, { action: 'Ativar' });
    setActivateWhatsApp(true);
  };

  return (
    <>
      <HideOrShowContainer display={hideOrShowComponentInCss(activeModule.id == ModuleIds.channels)}>
        <Container>
          <ChannelCard
            title="WhatsApp"
            imgSrc={Logos.WhatsAppLogo}
            onClickCallback={handleActivateWhatsApp}
            isChannelActivated={pack.isChannelActivated}
          />
        </Container>
        <Container>{activateWhatsApp && <WhatsAppActivationStep />}</Container>
      </HideOrShowContainer>
    </>
  );
};

export default ChannelActivation;
