import Logos from '../../assets';
import DrawerItem from '../../components/DrawerItem';
import { LogoutEvent } from '../../constants/MicrofrontEndEvents';
import { PG_LOGOUT, PG_OPEN_MOBILE_MENU, PG_OPEN_MODULE } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React, { FC } from 'react';
import { PublishEvent } from '../../services/EventPublisher';
import { FlexAlignCenter } from '../../styles';
import { Module } from '../../typings/Module';
import { Container, LogoutLink } from './style';

const MobileHeader: FC = () => {
  const { profile } = useAppContext();
  const { pack, modules, setActiveModule } = usePackContext();
  const { tracking } = useTrack();
  const sidebarRef = React.useRef<HTMLBdsSidebarElement>(null);

  const logout = async () => {
    tracking(PG_LOGOUT);
    await PublishEvent(LogoutEvent);
  };

  const handleOnClick = (packMenuItem: Module) => {
    tracking(PG_OPEN_MODULE, {
      moduleName: packMenuItem.title,
      moduleInternal: packMenuItem.isInternalModule ?? false,
    });

    if (packMenuItem.isExternalLink) {
      window.open(packMenuItem.url, '_blank');
    } else {
      setActiveModule(packMenuItem);
    }

    sidebarRef.current?.toggle();
  };

  const handleOpenMenu = () => {
    tracking(PG_OPEN_MOBILE_MENU);
    sidebarRef.current?.toggle();
  };

  const SideMenuItems = () => {
    return (
      <>
        {modules.map((module: any, index: any) => (
          <DrawerItem key={index} module={module} onClick={() => handleOnClick(module)} />
        ))}
      </>
    );
  };

  return (
    <>
      <Container>
        <img src={Logos.LogoExtended} alt="Ícone padrão" />
        <bds-icon
          name={pack.name == 'blipshop' ? 'avatar-user' : 'menu-hamburger'}
          size="x-large"
          onClick={handleOpenMenu}
        />
      </Container>
      <bds-sidebar ref={sidebarRef} sidebar-position="left">
        <FlexAlignCenter slot="header">
          <bds-typo variant="fs-16" bold="semi-bold">
            Olá, {profile?.FullName}
            <LogoutLink variant="fs-14" onClick={logout}>
              (Sair)
            </LogoutLink>
          </bds-typo>
        </FlexAlignCenter>
        <div slot="body">
          <SideMenuItems />
        </div>
      </bds-sidebar>
    </>
  );
};

export default MobileHeader;
