const ExternalUrls = {
  deskPrd: 'https://desk.blip.ai',
  deskHmg: 'https://hmg-desk.blip.ai',
  blipFoodsProductModulePRD: 'https://foods-module-product-manager.cs.blip.ai?appData=',
  blipFoodsProductModuleHMG: 'https://foods-module-product-manager.hmg-packs.blip.ai?appData=',
  blipFoodsOrderModulePRD: 'https://foods-module-order-manager.cs.blip.ai?appData=',
  blipFoodsOrderModuleHMG: 'https://foods-module-order-manager.hmg-packs.blip.ai?appData=',
  blipShopManagerModuleHMG: 'https://blip-shop-manager.hmg-cs.blip.ai?appData=',
  blipShopManagerModulePRD: 'https://blip-shop-manager.cs.blip.ai?appData=',
  blipGoConfigModuleHMG: 'https://take-blip-attendance-pack-plugin-config.hmg-cs.blip.ai/?appData=',
  blipGoConfigModulePRD: 'https://take-blip-attendance-pack-plugin-config.cs.blip.ai/?appData=',
  blipGoBotModuleHMG: 'https://take-blip-go-pack-configuration-bot.hmg-packs.blip.ai/?appData=',
  blipGoBotModulePRD: 'https://take-blip-go-pack-configuration-bot.cs.blip.ai/?appData=',
  blipGoChannelsModuleHMG: 'https://take-blip-attendance-pack-plugin-channels.hmg-packs.blip.ai/?appData=',
  blipGoChannelsModulePRD: 'https://take-blip-attendance-pack-plugin-channels.cs.blip.ai/?appData=',
  blipGoHelp: 'https://blipgo.take.net',
};

export default ExternalUrls;
