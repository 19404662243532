import {
  PG_BUTTON_SUPPORT_TICKET,
  PG_BUTTON_SUPPORT_WHATSAPP
} from '../../constants/TrackingEvents';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React, { useState } from 'react';
import { Text, Buttons, AlignItemsLeft } from './style';

const Support = () => {
  const { tracking } = useTrack();

  const handleClickHelpWpp = () => {
    window.open('http://api.whatsapp.com/send?1=pt_BR&phone=553172520321', '_blank')
    tracking(PG_BUTTON_SUPPORT_WHATSAPP);
  };

  const handleClickForms = () => {
    window.open('https://form.asana.com/?k=_rP8zQxnMoqWanjRPiQqqQ&d=15292640478948 ', '_blank');
    tracking(PG_BUTTON_SUPPORT_TICKET);
  };



  return (
    <bds-grid direction="column" align-items="left"  >
      <AlignItemsLeft>
        <Text>Tem alguma dúvida ou precisa de ajuda? Acesse nossos canais de suporte:</Text>
        <Buttons>
          <bds-button icon="whatsapp" variant="primary" style={{ margin: '40px', height: '40px' }} onClick={handleClickHelpWpp}>
            Falar pelo WhatsApp
          </bds-button>
          <bds-button icon="email" variant="primary" style={{ height: '40px' }} onClick={handleClickForms}>
            Abrir ticket de suporte
          </bds-button>
        </Buttons>
      </AlignItemsLeft>
    </bds-grid>


  );
};

export default Support;
