import { usePackContext } from '../../contexts/PackContext';
import React, { FC } from 'react';
import { Module } from '../../typings/Module';
import { ModuleComponents } from '../../typings/ModuleComponents';
import { IframeContainer } from './style';
import NoProducts from 'Pack-manager/components/NoProducts/NoProducts';
import Support from 'Pack-manager/components/Support/Support';

const IframeModules: FC<ModuleComponents> = ({ activeModule }) => {
  const { modules, merchantData } = usePackContext();


  const hideOrShowIframeInCss = (module: Module) => (module.id == activeModule.id ? 'block' : 'none');
  const IframeModules = () => {
    if (activeModule!.title === 'Canais') {
      return;
    } if (activeModule!.title === 'Suporte BlipFoods') {
      return <Support />;
    } else if (merchantData?.HasProduct) {
      return modules.map(

        (module, index) =>
          module.isIframe && (
            <IframeContainer key={index} display={hideOrShowIframeInCss(module)}>
              <iframe
                src={module.url}
                title={module.title}
                width="100%"
                height="100%"
                style={{ backgroundColor: '#FFFFFF !important' }}
              />
            </IframeContainer>
          ),
      );
    } else {
      return <NoProducts />;
    }
  };

  return <>{IframeModules()}</>;
};

export default IframeModules;
