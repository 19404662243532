import { useHashPrevention } from '../../hooks/useHashPrevention';

const EmptyRoute: React.FC = () => {
  // It's necessary to prevent hash at URL
  useHashPrevention('/');
  const urlHomePacks = process.env.REACT_APP_URL_PACKS_HOME;
  if (!!urlHomePacks) window.location.replace(urlHomePacks);
  return <span>Acessando Blip Packs. . .</span>;
};

export default EmptyRoute;
