import MobileHeader from '../../components/MobileHeader';
import Sidebar from '../../components/Sidebar';
import { LogoutEvent } from '../../constants/MicrofrontEndEvents';
import { PG_LOGOUT, PG_OPENED } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import useGraphApi from '../../hooks/useGraphApi';
import useTrack from '../../hooks/useTrack';
import ChannelActivation from '../../pages/ChannelActivation';
import IframeModules from '../../pages/IframeModules';
import PluginIntegration from '../../pages/PluginIntegration';
import WhatsAppConfiguration from '../../pages/WhatsAppConfiguration';
import React, { FC, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { BrowserRouter } from 'react-router-dom';
import { PublishEvent } from '../../services/EventPublisher';
import { Flex } from '../../styles';
import { ModuleComponents } from '../../typings/ModuleComponents';
import { ContentContainer, DesktopContainer, MobileContainer, TitleContainer } from './style';

const DefaultModules: FC<ModuleComponents> = ({ activeModule }) => {
  const { merchantData } = usePackContext();

  if (merchantData?.HasProduct) {
    return (
      <>
        <PluginIntegration activeModule={activeModule} />
        <WhatsAppConfiguration activeModule={activeModule} />
        <ChannelActivation activeModule={activeModule} />
      </>
    );
  } else {
    return (
      <>
        <ChannelActivation activeModule={activeModule} />
      </>
    );
  }
};

const MainContainer = () => {
  const { profile } = useAppContext();
  const { activeModule } = usePackContext();

  const { initFacebookSdk } = useGraphApi();
  const { tracking } = useTrack();

  const logout = () => {
    tracking(PG_LOGOUT);
    PublishEvent(LogoutEvent);
  };

  useEffect(() => {
    tracking(PG_OPENED);
    initFacebookSdk();
  }, []);

  return (
    <>
      <BrowserView>
        <Sidebar />
        {activeModule && (
          <DesktopContainer>
            <TitleContainer>
              <h1 id="title" color="#0747A6">
                {activeModule.title}
              </h1>
              <Flex>
                <bds-typo>Olá, {profile?.FullName}&nbsp;</bds-typo>
                <bds-typo onClick={logout} id="logout">
                  (Sair)
                </bds-typo>
              </Flex>
            </TitleContainer>
            {
              <ContentContainer>
                <IframeModules activeModule={activeModule} />
                <DefaultModules activeModule={activeModule} />
              </ContentContainer>
            }
          </DesktopContainer>
        )}
      </BrowserView>

      <MobileView>
        <BrowserRouter>
          <MobileHeader />
          <MobileContainer>
            {activeModule && (
              <ContentContainer>
                <IframeModules activeModule={activeModule} />
                <DefaultModules activeModule={activeModule} />
              </ContentContainer>
            )}
          </MobileContainer>
        </BrowserRouter>
      </MobileView>
    </>
  );
};

export default MainContainer;
