export const MY_EVENT = 'my-event';
export const PG_API_CALL = 'blippacks-management-general-apicall';
export const PG_API_CALL_ERROR = 'blippacks-management-general-apicall-error';
export const PG_OPENED = 'blippacks-management-general-opened';
export const PG_OPEN_MODULE = 'blippacks-management-general-module';
export const PG_LOGOUT = 'blippacks-management-general-logout';
export const PG_OPEN_MOBILE_MENU = 'blippacks-management-general-menuhamburguer';
export const PG_CLOSE_MOBILE_MENU = 'blippacks-management-general-menuclosed';
export const PG_ACTIVE_WHATSAPP_BUTTON = 'blippacks-management-general-activationbutton-whatsapp';
export const PG_WHATSAPP_ACTIVATED = 'blippacks-management-general-activated-whatsapp';
export const PG_WHATSAPP_ACTIVATION_ERROR = 'blippacks-management-general-activatederror-whatsapp';
export const PG_EMBEDDED_SIGNUP_INTERRUPTED = 'blippacks-management-general-embeddedsingup-interrupted';
export const PG_WHATSAPP_UPDATE_PROFILE = 'blippacks-management-general-updateprofile-whatsapp';
export const PG_WHATSAPP_ACTIVATE = 'blippacks-management-general-active-whatsapp';
export const PG_OPEN_EMBEDDED_SIGNUP = 'blippacks-management-general-facebook-loginFB';
export const PG_EMBEDDED_SIGNUP_STATUS = 'blippacks-management-general-facebook-loginFB-status';
export const PG_TEST_BLIP_CHAT = 'blippacks-management-general-testbutton-blipchat';
export const PG_ACTIVATIONBUTTON_WPP = 'blippacks-management-general-activationbutton-whatsapp';
export const PG_REOPEN_WPP_POPUP = 'blippacks-management-general-reopen-popup';
export const PG_BUTTON_ADD_PRODUCTS = 'blippacks-management-bf-add-product';
export const PG_BUTTON_IMPORT_PRODUCTS = 'blippacks-management-bf-import-from-ifood';
export const PG_IMPORT_MODAL_OPEN = 'blippacks-management-bf-modal-add-product-mannualy';
export const PG_BUTTON_ADD_PRODUCTS_MODAL = 'blippacks-management-bf-modal-add-product';
export const PG_BUTTON_SUPPORT_WHATSAPP = 'blippacks-management-bf-support-whatsapp';
export const PG_BUTTON_SUPPORT_TICKET = 'blippacks-management-bf-support-ticket';


