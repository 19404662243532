import BlipChatLogo from './BlipChatLogo.svg';
import InstagramLogo from './InstagramLogo.svg';
import LogoExtended from './LogoExtended.svg';
import LogoSmall from './LogoSmall.svg';
import MessengerLogo from './MessengerLogo.svg';
import WhatsAppLogo from './WhatsAppLogo.svg';
import Foods from './Foods.png';
import FoodsLogo from './FoodsLogo.svg';
import Bad from './Bad.svg';

const Logos = {
  WhatsAppLogo,
  BlipChatLogo,
  InstagramLogo,
  LogoExtended,
  LogoSmall,
  MessengerLogo,
  Foods,
  Bad,
  FoodsLogo
};

export default Logos;
