import React, { FC } from 'react';
import { Container } from './style';

type ChannelCard = {
  onClickCallback: () => void;
  isChannelActivated: boolean;
  title: string;
  imgSrc: string;
};

const ChannelCard: FC<ChannelCard> = ({ onClickCallback, imgSrc, title, isChannelActivated }) => {
  const ChannelActionOrStatus: FC = () => {
    if (isChannelActivated) {
      if (title === 'Blip Chat') {
        return (
          <bds-button onClick={onClickCallback} variant="primary">
            Testar canal
          </bds-button>
        );
      }

      return <bds-chip-tag color="success">Ativado</bds-chip-tag>;
    }

    return (
      <bds-button onClick={onClickCallback} variant="ghost">
        Ativar canal
      </bds-button>
    );
  };

  return (
    <Container>
      <bds-paper>
        <div>
          <img src={imgSrc} />
          <bds-typo variant="fs-20">{title}</bds-typo>
          <ChannelActionOrStatus />
        </div>
      </bds-paper>
    </Container>
  );
};

export default ChannelCard;
