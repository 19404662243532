import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${blipColors['color-neutral-medium-wave']};
  border-radius: 8px;
  padding: 24px;

  bds-typo {
    text-align: center;
  }

  bds-button {
    display: flex;
    justify-content: right;
  }

  bds-input {
    margin-top: 24px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: 1fr;
  column-gap: 24px;
  row-gap: 24px;
  align-items: center;
  margin: 24px 0;
`;

export const ImageUploadCircle = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
  input {
    display: none;
  }
`;

export const ImageRow = styled.div`
  display: flex;
  align-items: center;
  bds-typo {
    margin-left: 16px;
  }
  margin-bottom: 16px;
`;
