import base64 from 'base-64';

const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const decodeAndEncodeAuthKey = (botShortName: string, botKey: string): string => {
  return base64.encode(`${botShortName}:${base64.decode(botKey)}`);
};

const getUploadUrl = async (msgingServer: string, authorizationKey: string): Promise<string> => {
  const uploadSessionBody = {
    id: crypto.randomUUID(),
    to: 'postmaster@media.msging.net',
    method: 'get',
    uri: '/upload-media-uri',
  };

  const response = await fetch(`${msgingServer}/commands`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Key ${authorizationKey}`,
    },
    body: JSON.stringify(uploadSessionBody),
  })
    .then(handleErrors)
    .then(res => res.json())
    .catch(console.error);

  return response.resource;
};

export const IsImageSizeAcceptable = (imageFile: File): Promise<boolean> => {
  const image = new Image();
  image.src = URL.createObjectURL(imageFile);
  const promise = new Promise<boolean>((resolve, reject) => {
    image.onload = () => {
      if (image.width >= 192 && image.height >= 192) {
        resolve(true);
      }
      resolve(false);
    };
  });

  return promise;
};

export const UploadImage = async (msgingServer: string, botShortName: string, botKey: string, imageFile: File) => {
  const authorizationKey = decodeAndEncodeAuthKey(botShortName, botKey);

  const uploadUrl = await getUploadUrl(msgingServer, authorizationKey);
  const response = await fetch(uploadUrl, {
    method: 'POST',
    headers: {
      'Content-type': imageFile.type,
      Authorization: `Key ${authorizationKey}`,
    },
    body: imageFile,
  })
    .then(handleErrors)
    .then(response => response.json())
    .catch(console.error);

  return response.mediaUri;
};
