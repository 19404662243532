import { applyPolyfills, defineCustomElements } from 'blip-ds/loader';
import { AppProvider } from './contexts/AppContext';
import { PackProvider } from './contexts/PackContext';
import React from 'react';
import { UserData } from './typings/UserData';
import { GlobalStyles } from './styles';
import MainContainer from './pages/MainContainer/MainContainer';

type AppProps = {
  userdata: UserData;
};

export const App: React.FC<AppProps> = ({ userdata }) => {
  applyPolyfills().then(() => {
    defineCustomElements(window);
  });

  return (
    <>
      <GlobalStyles />
      <AppProvider access_token={userdata.access_token} profile={userdata.profile}>
        <PackProvider>
          <MainContainer />
        </PackProvider>
      </AppProvider>
    </>
  );
};
