import { getOidcBaseConfig } from 'constants/Oidc';
import { ANALYTICS_KEY_HMG, ANALYTICS_KEY_PRD } from 'constants/TrackingEvents';
import { AuthProvider } from 'oidc-react';
import EmptyRoute from 'pages/EmptyRoute';
import MainContainer from 'pages/MainContainer';
import PackManager from 'pages/PackManager';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initializeSegment } from 'services/Segment';

const App: React.FC = () => {
  const urlRedirect = window.location.href;
  const oidc = getOidcBaseConfig(urlRedirect);

  const isNonAuthAccess = ['/'].includes(window.location.pathname);

  useEffect(() => {
    process.env.REACT_APP_ENV === 'production'
      ? initializeSegment(ANALYTICS_KEY_PRD)
      : initializeSegment(ANALYTICS_KEY_HMG);
  }, []);

  const routesWithAuthProvider = () => (
    <AuthProvider {...oidc}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route index element={<EmptyRoute />} />
            <Route path="pack-manager/*" element={<PackManager />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
  return isNonAuthAccess ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<EmptyRoute />}></Route>
      </Routes>
    </BrowserRouter>
  ) : (
    routesWithAuthProvider()
  );
};

export default App;
