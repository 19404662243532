import axios, { AxiosInstance } from 'axios';
import { PG_API_CALL_ERROR } from '../constants/TrackingEvents';
import qs from 'qs';
import { handleApiErrorResponseForTrack } from '../utils';
import { logTrack } from './LogTrackService';

export class ApiServiceFoods {
  private server: string;
  private api: AxiosInstance;

  constructor(server: string) {
    this.server = server;
    this.api = this.instantiateApi();
  }

  private getHeadersDefault(access_token: string | undefined): any {
    const headersDefault: any = {
      'Content-Type': 'application/json',
    };

    if (!!access_token) {
      headersDefault['Authorization'] = `bearer ${access_token}`;
    }

    return headersDefault;
  }

  private instantiateApi(): AxiosInstance {
    const api = axios.create({
      baseURL: `${this.server}/api/v1`,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      validateStatus: status => status >= 200 && status < 300,
    });

    return api;
  }

  async get<T = any>(url: string, parameters?: any, token?: any): Promise<T> {
    try {
      if (token) {
        this.api.defaults.headers = this.getHeadersDefault(token);
      }

      const response = await this.api.get<T>(url, parameters);
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `GET ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });
      return Promise.reject(error);
    }
  }

  async post<T = any>(url: string, body: any, token?: any): Promise<T> {
    try {
      if (token) {
        this.api.defaults.headers = this.getHeadersDefault(token);
      }

      const response = await this.api.post<T>(url, body);
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `POST ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });
      return Promise.reject(error);
    }
  }

  async patch<T = any>(url: string, body: T, token?: any): Promise<T> {
    try {
      if (token) {
        this.api.defaults.headers = this.getHeadersDefault(token);
      }

      const response = await this.api.patch<T>(url, body);
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `PATCH ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });
      return Promise.reject(error);
    }
  }

  async delete<T = any>(url: string, parameters?: any, token?: any): Promise<T> {
    try {
      if (token) {
        this.api.defaults.headers = this.getHeadersDefault(token);
      }

      const response = await this.api.delete<T>(url, {
        params: parameters,
      });
      return Promise.resolve(response.data);
    } catch (error: any) {
      logTrack(PG_API_CALL_ERROR, {
        apiCall: `DELETE ${url}`,
        status: 'Falha',
        error: handleApiErrorResponseForTrack(error),
      });
      return Promise.reject(error);
    }
  }
}
