import { App } from 'Pack-manager/App';
import { Profile } from 'Pack-manager/typings/OIDCProfile';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { useAuth } from 'oidc-react';
import { FC, useEffect, useState } from 'react';

type UserData = {
  access_token: string | undefined;
  profile: Profile | undefined;
};

const PackManager: FC = () => {
  const [userData, setUserData] = useState<UserData>({
    access_token: '',
    profile: undefined,
  });
  const auth = useAuth();
  useHashPrevention('/pack-manager');

  useEffect(() => {
    document.title = 'Gestão de Packs';
    setUserData({
      access_token: auth.userData?.access_token,
      profile: auth.userData?.profile,
    });
  }, [auth.userData]);

  return <>{userData?.access_token ? <App userdata={userData}></App> : <></>}</>;
};

export default PackManager;
