import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const CenterItems = styled.div`
  width: 650px;
  height: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const EmptyProductsInfo = styled.div`
  margin-left: 19px;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const TextBold = styled.p`
  font-weight: 700;
  font-size: 23px;
  font-family: Carbona, Nunito Sans, sans-serif !important;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 19px;
  font-family: Carbona, Nunito Sans, sans-serif !important;
`;
