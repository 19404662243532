import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 32px;

  bds-paper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 210px;
    width: 260px;
    padding: 10px;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    img {
      height: 80px;
    }

    bds-chip-tag {
      display: inline-flex;
    }
  }
  bds-paper:hover {
    box-shadow: rgb(7 71 166 / 12%) 0px 10px 16px; /* Primary */
  }
`;
