export const ModuleIds = {
  desk: '1',
  blipFoodsProducts: '2',
  blipFoodsOrder: '3',
  blipShopManager: '4',
  configuration: '5',
  whatsAppProfile: '6',
  channels: '7',
  blipGoConfig: '8',
  blipGoChannels: '9',
  blipGoHelp: '10',
  blipGoBot: '11',
  blipSupport: '12'
};
