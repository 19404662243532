import React, { FC } from 'react';
import { Module } from '../../typings/Module';
import { Container } from './style';
import { usePackContext } from 'Pack-manager/contexts/PackContext';

type SidebarItemProps = {
  module: Module;
  onClick: (menuItem: Module) => void;
};

const SiderbarItem: FC<SidebarItemProps> = ({ module, onClick }) => {
  const handleOnClick = () => onClick && onClick(module);
  const { activeModule } = usePackContext();

  return (
    <Container>
      <bds-tooltip tooltip-text={module.tooltipText} position="right-bottom">
        <bds-icon name={module.iconName} size="x-large" onClick={handleOnClick} color={activeModule?.title == module.title ? '#1E6BF1 !important': '#FFFFFF'} />
      </bds-tooltip>
    </Container>
  );
};

export default SiderbarItem;
