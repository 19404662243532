import { ModuleIds } from '../../constants/ModuleIds';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import { usePluginEventsHandler } from '../../hooks/usePluginEventsHandler';
import React, { FC, useEffect } from 'react';
import { InjectMicrofrontEnd } from '../../services/MicrofrontEnd';
import { HideOrShowContainer } from '../../styles';
import { Module } from '../../typings/Module';
import { ModuleComponents } from '../../typings/ModuleComponents';
import { Profile } from '../../typings/OIDCProfile';
import { RouterData } from '../../typings/RouterData';
import { hideOrShowComponentInCss } from '../../utils';

type PluginProps = {
  isSelfOnboarding: boolean;
  userData: Profile | undefined;
  routerData: RouterData;
  tenantId: string;
};

const PluginIntegration: FC<ModuleComponents> = ({ activeModule }) => {
  usePluginEventsHandler();
  const { profile } = useAppContext();
  const { pack } = usePackContext();

  useEffect(() => {
    if (pack.pluginData?.url && pack.pluginData?.tag) {
      const pluginProps: PluginProps = {
        userData: profile,
        isSelfOnboarding: false,
        routerData: pack.routerData,
        tenantId: pack.tenant.id,
      };

      pluginProps.routerData &&
        InjectMicrofrontEnd('container-plugins', pack.pluginData.url, pack.pluginData.tag, pluginProps);
    }
  }, [pack.pluginData, pack.routerData, pack.tenant.id, profile]);

  return (
    <HideOrShowContainer display={hideOrShowComponentInCss(activeModule.id == ModuleIds.configuration)}>
      <div id="container-plugins" />
    </HideOrShowContainer>
  );
};

export default PluginIntegration;
