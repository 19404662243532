import Logos from '../../assets';
import {
  PG_BUTTON_ADD_PRODUCTS,
  PG_BUTTON_IMPORT_PRODUCTS,
  PG_IMPORT_MODAL_OPEN,
} from '../../constants/TrackingEvents';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React, { useState } from 'react';
import { Container, CenterItems, TextBold, Text, Buttons, EmptyProductsInfo } from './style';
import Modal from '../Modal/Modal';

const NoProducts = () => {
  const { tracking } = useTrack();

  const { setMerchantData, modules, setActiveModule } = usePackContext();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClickAddProduct = () => {
    setActiveModule(modules[1]);
    setMerchantData({
      HasProduct: true,
    });
    tracking(PG_BUTTON_ADD_PRODUCTS);
  };

  const handleClickImport = () => {
    setIsOpenModal(true);
    tracking(PG_BUTTON_IMPORT_PRODUCTS);
    tracking(PG_IMPORT_MODAL_OPEN);
  };

  const closeModal = (isOK: boolean) => {
    setIsOpenModal(isOK);
  };

  return (
    <Container>
      <Modal isOpen={isOpenModal} onConfirm={closeModal} />
      <CenterItems>
        <img src={Logos.Foods} style={{ height: '100%' }} alt="Blip Foods" />
        <EmptyProductsInfo>
          <TextBold>Adicione produtos para começar a vender!</TextBold>
          <Text>Escolha a melhor forma para criar seu catálogo e iniciar suas vendas.</Text>
          <Buttons>
            <bds-button icon="add" variant="tertiary" style={{ marginRight: '20px' }} onClick={handleClickAddProduct}>
              Adicionar produto
            </bds-button>
            <bds-button icon="upload" variant="primary" onClick={handleClickImport}>
              Importar do iFood
            </bds-button>
          </Buttons>
        </EmptyProductsInfo>
      </CenterItems>
    </Container>
  );
};

export default NoProducts;
