import { useAppContext } from '../contexts/AppContext';
import { useEffect } from 'react';
import { PluginEvents } from '../services/EventExecuter';
import { EventData } from '../typings/EventData';

type RegisteredEventsOpts = {
  [key: string]: (event: Event) => any;
};

export const usePluginEventsHandler = () => {
  const { apiService } = useAppContext();

  useEffect(() => {
    const registeredEvents: RegisteredEventsOpts = {};

    for (const key in PluginEvents) {
      if (Object.prototype.hasOwnProperty.call(PluginEvents, key)) {
        const callback = PluginEvents[key];
        const eventHandler = (event: Event) => {
          const customEvent = event as CustomEvent;
          const data = customEvent.detail as EventData;
          const result = callback(apiService, data);

          window.dispatchEvent(
            new CustomEvent(data.eventId, { detail: new EventData(data.eventId, data.eventName, result) }),
          );
        };

        registeredEvents[key] = eventHandler;
        window.addEventListener(key, eventHandler);
      }
    }

    return () => {
      for (const key in registeredEvents) {
        if (Object.prototype.hasOwnProperty.call(registeredEvents, key)) {
          const eventHandler = registeredEvents[key];
          window.removeEventListener(key, eventHandler);
        }
      }
    };
  }, [apiService]);
};
