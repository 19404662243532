import base64 from 'base-64';
import { useCallback, useMemo } from 'react';
import {
  INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE,
  INSTALLATIONS_ROUTE,
  INSTALLATION_BY_ID,
  PLUGIN_ROUTE,
} from '../constants/ApiRoutes';
import ExternalUrls from '../constants/ExternalUrls';
import { ModuleIds } from '../constants/ModuleIds';
import { PackNames } from '../constants/PackNames';
import { PlanNames } from '../constants/PlanNames';
import { useAppContext } from '../contexts/AppContext';
import { Installation, InstallationDetails } from '../typings/Installation';
import { Module, ModuleAppData } from '../typings/Module';
import { Pack } from '../typings/Pack';
import { PluginData } from '../typings/PluginData';

export type Application = {
  id: string;
  name?: string;
  description?: string;
  channels?: string;
  version?: string;
  routerShortName?: string;
  plugin?: PluginData;
};

export const usePack = () => {
  const { apiService, profile, settings, accessToken, apiServiceFoods } = useAppContext();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search.toLowerCase()), []);
  const isHomologation = settings.Environment == 'staging';

  const getPackNameByPackId = useCallback(
    (packId: number) => {
      const packNamesById = {
        [process.env.REACT_APP_BLIP_FOODS_ID as string]: PackNames.foods,
      };

      return packNamesById[packId] ? packNamesById[packId] : '';
    },
    [isHomologation, settings],
  );

  const getPackName = useCallback(
    (packId: string) => {
      const packNameFromUrlWhenHmgEnv = isHomologation && urlParams.get('packname');
      return packNameFromUrlWhenHmgEnv ? packNameFromUrlWhenHmgEnv : getPackNameByPackId(+packId);
    },
    [isHomologation, urlParams, getPackNameByPackId],
  );

  const getLastInstallationAndDetails = useCallback(
    async (installations: Installation[]) => {
      const currentInstallation = installations.reduce((prev, current) => {
        return prev.installationId > current.installationId ? prev : current;
      });

      const installationDetails = await apiService.get<InstallationDetails>(
        `${INSTALLATION_BY_ID}/${currentInstallation.installationId}`,
      );
      return { currentInstallation, installationDetails };
    },
    [apiService],
  );

  const getSpecificInstallationAndDetails = useCallback(
    async (installations: Installation[], installationId: string | null) => {
      const currentInstallation = installations.find(installation => installation.installationId == installationId);
      if (currentInstallation) {
        const installationDetails = await apiService.get<InstallationDetails>(
          `${INSTALLATION_BY_ID}/${currentInstallation.installationId}`,
        );
        return { currentInstallation, installationDetails };
      }
      throw new Error('No installation for this ID.');
    },
    [apiService],
  );

  const fetchPluginFromPack = useCallback(
    async (packId: string) => {
      return await apiService.get<PluginData>(`${PLUGIN_ROUTE}/${packId}`, false);
    },
    [apiService],
  );

  const getBotInfo = (botKey: string) => {
    botKey = botKey.replace('Key ', '');
    botKey = base64.decode(botKey);
    const [shortName, accessKey] = botKey.split(':');
    return { shortName, accessKey: base64.encode(accessKey) };
  };

  const fetchPackData = useCallback(async () => {
    const packId = urlParams.get('packid');
    const installationId = urlParams.get('installationid');

    let installations: Installation[];
    let pack: Pack = {} as Pack;
    let isOwner = false;
    let installationData: { currentInstallation: Installation; installationDetails: InstallationDetails };

    if (packId) {
      const response = await apiService.get<{ installations: Installation[]; count: number }>(
        `${INSTALLATIONS_ROUTE}?packid=${packId}`,
      );

      installations = response.installations;
    } else {
      installations = await apiService.get<Installation[]>(
        `${INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE}/${profile?.email}`,
      );
    }

    if (installations.length == 0) {
      pack.id = packId != null ? packId : '';
      pack.name = getPackName(pack.id);
      pack.tenant = { id: '' };

      return { pack, isOwner };
    }

    isOwner = true;

    try {
      installationData = await getSpecificInstallationAndDetails(installations, installationId);
    } catch {
      installationData = await getLastInstallationAndDetails(installations);
    }

    let temporaryTokenAdmin;
    await apiServiceFoods
      .post('/authentication', {
        staticToken: settings.AdminTokenApi,
      })
      .then(async tokenAuthentication => {
        temporaryTokenAdmin = tokenAuthentication.temporaryToken;
        await apiServiceFoods
          .get(`/merchant/details?email=${profile?.email}`, '', temporaryTokenAdmin)
          .then(async merchant => {
            const { shortName, accessKey } = getBotInfo(merchant.botKey);
            pack = {
              id: installationData.currentInstallation.applicationId,
              routerData: {
                shortName,
                accessKey,
                skillTransbordo: {
                  shortName: '',
                  accessKey: '',
                },
              },
              isChannelActivated: installationData.currentInstallation.isChannelActived,
              pluginData: await fetchPluginFromPack(installationData.currentInstallation.applicationId),
              name: getPackName(installationData.currentInstallation.applicationId),
              tenant: installationData.installationDetails.tenant,
              companyName: installationData.installationDetails.clientIdentity,
            };
          });
      });

    return { pack, isOwner };
  }, [
    apiService,
    fetchPluginFromPack,
    getLastInstallationAndDetails,
    getPackName,
    getSpecificInstallationAndDetails,
    profile?.email,
    urlParams,
    apiServiceFoods,
  ]);

  const getIsFreePlan = useCallback((planName: string) => planName == PlanNames.standard, []);

  const getDeskModule = useCallback((): Module => {
    return {
      id: ModuleIds.desk,
      title: 'Atendimento',
      tooltipText: 'Atendimento',
      iconName: 'agent',
      url: isHomologation ? ExternalUrls.deskHmg : ExternalUrls.deskPrd,
      isIframe: true,
      isInternalModule: true,
    };
  }, [isHomologation]);

  const getWhatsAppProfileEditModule = useCallback((): Module => {
    return {
      id: ModuleIds.whatsAppProfile,
      title: 'Perfil WhatsApp Business',
      tooltipText: 'Perfil WhatsApp Business',
      iconName: 'avatar-user',
      isIframe: false,
      isInternalModule: true,
    };
  }, []);

  const getConfigModule = useCallback((): Module => {
    return {
      id: ModuleIds.configuration,
      title: 'Configurações',
      tooltipText: 'Configurações',
      iconName: 'settings-general',
      isIframe: false,
    };
  }, []);

  const getSupportModule = useCallback((): Module => {
    return {
      id: ModuleIds.blipSupport,
      title: 'Suporte BlipFoods',
      tooltipText: 'Suporte BlipFoods',
      iconName: 'question',
      isIframe: false,
    };
  }, []);
  const getChannelsModule = useCallback((): Module => {
    return {
      id: ModuleIds.channels,
      title: 'Canais',
      tooltipText: 'Canais',
      iconName: 'builder-publish-bot',
      isIframe: false,
      isInternalModule: true,
    };
  }, []);

  const getDefaultWhatsAppModules = useCallback(
    (isOwner: boolean, isFreePlan: boolean): Module[] => {
      const modules: Module[] = [];

      if (isOwner && !isFreePlan) {
        modules.push(getWhatsAppProfileEditModule());
        modules.push(getChannelsModule());
      }

      return modules;
    },
    [getWhatsAppProfileEditModule, getChannelsModule],
  );

  const getFoodsPackModules = useCallback(
    (isOwner: boolean, isFreePlan: boolean, appData: string | undefined) => {
      let modules: Module[] = [getDeskModule()];

      if (!appData || !isOwner) {
        return modules;
      }
      modules.push(
        {
          id: ModuleIds.blipFoodsProducts,
          title: 'Produtos',
          tooltipText: 'Produtos',
          iconName: 'tag',
          url: isHomologation
            ? `${ExternalUrls.blipFoodsProductModuleHMG}${appData}`
            : `${ExternalUrls.blipFoodsProductModulePRD}${appData}`,
          isIframe: true,
        },
        {
          id: ModuleIds.blipFoodsOrder,
          title: 'Pedidos',
          tooltipText: 'Pedidos',
          iconName: 'notes',
          url: isHomologation
            ? `${ExternalUrls.blipFoodsOrderModuleHMG}${appData}`
            : `${ExternalUrls.blipFoodsOrderModulePRD}${appData}`,
          isIframe: true,
        },
      );

      modules = modules.concat(getDefaultWhatsAppModules(isOwner, isFreePlan));
      modules.push(getConfigModule());
      modules.push(getSupportModule());

      return modules;
    },
    [isHomologation, getDeskModule, getDefaultWhatsAppModules, getConfigModule, getSupportModule],
  );

  const getModules = useCallback(
    (pack: Pack, isOwner: boolean) => {
      const isFreePlan = getIsFreePlan(pack.tenant.name ?? '');

      const appData: ModuleAppData = {
        accountToken: accessToken,
        profile: profile,
        routerData: pack.routerData,
        tenantId: pack.tenant.id,
      };

      appData.profile!.isOwner = isOwner;
      const encodedAppData: string | undefined = base64.encode(JSON.stringify(appData));
      return getFoodsPackModules(isOwner, isFreePlan, encodedAppData);
    },
    [accessToken, profile, getFoodsPackModules, getIsFreePlan],
  );

  return { fetchPackData, fetchPluginFromPack, getPackName, getIsFreePlan, getModules };
};
