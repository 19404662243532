import { GraphApiUrl } from '../constants/GraphApi';
import { PG_API_CALL, PG_EMBEDDED_SIGNUP_INTERRUPTED } from '../constants/TrackingEvents';
import { useAppContext } from '../contexts/AppContext';
import { Facebook as FB } from '../services/FacebookSDK.js';
import { logTrack } from '../services/LogTrackService';
import { PhoneNumber, Waba } from '../typings/WhatsAppActivation';

const useGraphApi = () => {
  const { settings } = useAppContext();

  const initFacebookSdk = async () => {
    await FB.init({
      appId: settings.FacebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v16.0',
    });
  };

  const launchWhatsAppSignup = async (callback: (arg0: string) => any) => {
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          logTrack(PG_API_CALL, { apiCall: 'GRAPH API launchWhatsAppSignup', status: 'Sucesso' });
          const accessToken = response.authResponse.accessToken;
          callback && callback(accessToken);
        } else {
          logTrack(PG_EMBEDDED_SIGNUP_INTERRUPTED, { ownerSO: 'yankee' });
          console.error(response);
        }
      },
      {
        auth_type: 'reauthorize',
        scope: 'business_management,whatsapp_business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {
            // Prefilled data can go here
          },
        },
      },
    );
  };

  const getWabaPhoneNumbersById = async (wabaId: string): Promise<PhoneNumber[]> => {
    const url = `${GraphApiUrl}/${wabaId}/phone_numbers?fields=display_phone_number&access_token=${settings.FacebookBusinessSystemUserToken}`;
    const response = await fetch(url);

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    const phoneNumbers: PhoneNumber[] = data.data.map(({ display_phone_number, id }: any) => ({
      displayPhoneNumber: display_phone_number,
      id,
    }));
    return phoneNumbers;
  };

  const getWabaIdsAndAppName = async (inputToken: string): Promise<{ wabaIds: string[]; applicationName: string }> => {
    const url = `${GraphApiUrl}/debug_token?input_token=${inputToken}&access_token=${settings.FacebookBusinessSystemUserToken}`;
    const response = await fetch(url);

    if (!response.ok) {
      return Promise.reject(new Error(JSON.stringify(response)));
    }

    const data = await response.json();

    try {
      const waScope = data.data.granular_scopes.find(({ scope }: any) => scope === 'whatsapp_business_management');
      return Promise.resolve({ wabaIds: waScope.target_ids, applicationName: data.data.application });
    } catch (error) {
      console.error('Error finding Waba granular scopes: ', error);
      return Promise.reject(new Error(error as string));
    }
  };

  const getWabas = async (inputToken: string): Promise<{ wabas: Waba[]; applicationName: string }> => {
    const wabas: Waba[] = [];

    try {
      const response = await getWabaIdsAndAppName(inputToken);
      for (const wabaId of response.wabaIds) {
        const wabaPhoneNumbers = await getWabaPhoneNumbersById(wabaId);
        wabas.push({
          id: wabaId,
          phoneNumbers: wabaPhoneNumbers,
        });
      }
      return Promise.resolve({ wabas: wabas, applicationName: response.applicationName });
    } catch (error) {
      return Promise.reject(new Error(error as string));
    }
  };

  return { initFacebookSdk, launchWhatsAppSignup, getWabas };
};

export default useGraphApi;
