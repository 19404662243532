import Logos from '../../assets';
import SidebarItem from '../../components/SidebarItem';
import { PG_OPEN_MODULE } from '../../constants/TrackingEvents';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React from 'react';
import { Module } from '../../typings/Module';
import { BottomConfigSection, DesktopSidebar, ModulesSection } from './style';

const Sidebar = () => {
  const { setActiveModule } = usePackContext();
  const { modules } = usePackContext();
  const { tracking } = useTrack();

  const handleOnClick = (packMenuItem: Module) => {
    tracking(PG_OPEN_MODULE, {
      moduleName: packMenuItem.title,
      moduleInternal: packMenuItem.isInternalModule ?? false,
    });

    if (packMenuItem.isExternalLink) {
      window.open(packMenuItem.url, '_blank');
    } else {
      setActiveModule(packMenuItem);
    }
  };

  const SideMenuItems = () => {
    return (
      <>
        {modules.map((module: any, index: any) => (
          <SidebarItem key={index} module={module} onClick={() => handleOnClick(module)} />
        ))}
      </>
    );
  };

  return (
    <DesktopSidebar>
      <img src={Logos.FoodsLogo} alt="Blip Foods" />
      <ModulesSection>
        <SideMenuItems />
      </ModulesSection>
      <BottomConfigSection>
        <bds-typo variant="fs-12">Versão</bds-typo>
        <bds-typo variant="fs-12">0.3.0</bds-typo>
      </BottomConfigSection>
    </DesktopSidebar>
  );
};

export default Sidebar;
