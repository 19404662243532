import { Profile } from '../typings/OIDCProfile';

type PluginProps = {
  userData: Profile | undefined;
  isSelfOnboarding: boolean;
};

export function InjectMicrofrontEnd(idContainer: string, urlScript: string, tagMfe: string, pluginProps: PluginProps) {
  const idScriptImport = `${idContainer}-script-mfe`;
  if (!window.document.getElementById(idScriptImport)) {
    const script = document.createElement('script');
    script.id = idScriptImport;
    script.src = urlScript;
    document.body.appendChild(script);
  }

  const container = window.document.getElementById(idContainer);
  const idMfe = `${container?.id}-fragment`;

  if (!container?.ownerDocument.getElementById(idMfe)) {
    const mfe = document.createElement(tagMfe);
    mfe.id = idMfe;

    mfe.setAttributeNS(null, 'pluginProps', JSON.stringify(pluginProps));
    container?.appendChild(mfe);
  }
}
