import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
      body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-family: Carbona, Nunito Sans, sans-serif !important;
      }

      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
      }
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HideOrShowContainer = styled.div<{ display: string }>`
  display: ${props => props.display};
`;
