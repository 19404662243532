import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const DesktopContainer = styled.div`
  margin-left: 64px;
  padding: 20px;
  height: calc(100vh - 24px);
`;

export const MobileContainer = styled.div`
  padding: 24px;
  width: 100vw;
  height: 100vh;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h1 {
    font-size: 32px;
    line-height: 100%;
  }

  #logout {
    color: #292929;
    cursor: pointer;
  }

  #title {
    color: #292929;
  }
`;

export const ContentContainer = styled.div`
  height: calc(100% - 32px);
`;
