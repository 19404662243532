import { ModuleIds } from '../constants/ModuleIds';
import { usePack } from '../hooks/usePack';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { createToastError } from '../services/Toast';
import { Module } from '../typings/Module';
import { Pack } from '../typings/Pack';
import { MerchantData } from '../typings/MerchantData';
import { useAppContext } from '../../Pack-manager/contexts/AppContext';

type PackContextData = {
  pack: Pack;
  modules: Module[];
  activeModule: Module | undefined;
  isOwner: boolean;
  merchantData: MerchantData | undefined;
  setActiveModule: React.Dispatch<React.SetStateAction<Module | undefined>>;
  activateWppChannel: () => void;
  setMerchantData: React.Dispatch<React.SetStateAction<MerchantData | undefined>>;
};

export const PackContext = createContext<PackContextData>({} as PackContextData);

export const PackProvider: FC = ({ children }) => {
  const [pack, setPack] = useState({} as Pack);
  const [initialized, setInitialized] = useState(false);
  const { fetchPackData, getModules } = usePack();
  const [isOwner, setIsOwner] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [activeModule, setActiveModule] = useState<Module | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [merchantData, setMerchantData] = useState<MerchantData | undefined>({
    HasProduct: true,
  });
  const [merchantHasProcuts, setMerchantHasProcuts] = useState(true);
  const { apiServiceFoods, settings, profile } = useAppContext();

  const activateWppChannel = () => {
    setPack(pack => ({ ...pack, isChannelActivated: true }));
  };

  useEffect(() => {
    (async () => {
      try {
        const packData = await fetchPackData();
        if (packData && packData.pack) {
          setIsOwner(packData.isOwner);
          setPack(packData.pack);
          if (packData.pack.isChannelActivated) {
            setModules(getModules(packData.pack, packData.isOwner));
          } else {
            setModules(
              getModules(packData.pack, packData.isOwner).filter(
                (module: any) => module.id != ModuleIds.whatsAppProfile,
              ),
            );
          }
        }
      } catch (error) {
        createToastError('Falha ao obter o Pack', 'Por favor, tente novamente daqui alguns minutos.');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const tokenAuthentication = await apiServiceFoods.post('/authentication', {
        staticToken: settings.AdminTokenApi,
      });
      const temporaryTokenAdmin = tokenAuthentication.temporaryToken;

      const merchant = await apiServiceFoods.get(`/merchant/details?email=${profile?.email}`, '', temporaryTokenAdmin);

      const tokenMerchant = await apiServiceFoods.post('/authentication', {
        staticToken: merchant.token,
      });
      const temporaryTokenMerchant = tokenMerchant.temporaryToken;

      const hasProducts = await apiServiceFoods.get('/product/has-products', '', temporaryTokenMerchant);

      setMerchantHasProcuts(hasProducts);

      setMerchantData({
        BotKey: merchant.botKey,
        Email: merchant.email,
        FaviconBase64: merchant.FaviconBase64,
        HasSode: merchant.hasSode,
        Id: merchant.id,
        ImageUrl: merchant.imageUrl,
        MinimumPriceValue: merchant.minimumPriceValue,
        Name: merchant.name,
        Organization: merchant.organization,
        Phone: merchant.phone,
        Token: merchant.token,
        HasProduct: hasProducts,
      });
    })();
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      if (merchantHasProcuts) {
        setActiveModule(modules[0]);
      } else {
        setActiveModule(modules[1]);
      }
      setInitialized(true);
    }
  }, [modules]);

  return initialized ? (
    <>
      <PackContext.Provider
        value={{
          pack,
          modules,
          activeModule,
          isOwner,
          merchantData,
          setActiveModule,
          activateWppChannel,
          setMerchantData,
        }}
      >
        {children}
      </PackContext.Provider>
    </>
  ) : (
    <>{isLoading && <bds-loading-page />}</>
  );
};

export function usePackContext(): PackContextData {
  const context = useContext(PackContext);

  if (!context) {
    throw new Error('Use Context must be used within a Provider');
  }

  return context;
}
