import { useState } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { usePackContext } from '../contexts/PackContext';
import { getDeviceType } from '../utils';
import { PackNames } from 'Pack-manager/constants/PackNames';
import { track } from 'services/Segment';

type TrackProps = {
  screenName?: string;
  buttonName?: string;
  linkName?: string;
  userOption?: string;
  activationOption?: string;
  statusButton?: string;
  status?: string;
  moduleName?: string;
  error?: string;
  moduleInternal?: boolean;
  action?: string;
};

type DefaultProps = {
  packName: string;
  packId: string;
  botIdentifier: string;
  screenResolution: string;
  operationalSystem: string;
  deviceType: string;
  tenant: string;
  plan?: string;
  merchantName: string;
  isOwner: boolean;
  ownerSO: string;
};

const useTrack = () => {
  const { pack } = usePackContext();
  const { profile } = useAppContext();
  const { isOwner } = usePackContext();
  const [defaultProperties, updateDefaultProperties] = useState<DefaultProps>({
    packName: PackNames.foods,
    packId: pack.id,
    botIdentifier: pack.routerData?.shortName ?? '',
    screenResolution: `${screen.width}x${screen.height}`,
    operationalSystem: navigator.userAgentData?.platform ?? '',
    deviceType: getDeviceType(),
    tenant: pack.tenant.id,
    plan: pack.tenant.plan?.name ?? '',
    merchantName: !!pack.companyName ? pack.companyName : profile?.FullName,
    isOwner: isOwner,
    ownerSO: 'yankee',
  });

  const tracking = (event: string, trackProps?: TrackProps) => {
    const payload = { ...defaultProperties, ...trackProps };
    track(event, payload);
  };

  return { tracking, updateDefaultProperties };
};

export default useTrack;
