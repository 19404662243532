import styled from 'styled-components';

export const Container = styled.div`
  bds-select,
  bds-input {
    margin-bottom: 16px;
    width: 300px;
  }
  margin: 8px 0;
`;

export const ContainerActions = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 32px;
`;

export const ContainerFormLabel = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  bds-tooltip {
    height: 24px;
  }
`;

export const Form = styled.form`
  max-width: 400px;
  margin-top: 40px;

  label {
    margin-bottom: 8px;
    display: block;
  }

  label:not(:first-child) {
    margin-top: 32px;
  }
`;

export const PinContainer = styled.div`
  position: relative;

  bds-icon {
    position: absolute;
    right: 6px;
    top: 18px;
  }
`;
