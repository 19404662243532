import React, { createContext, useMemo, useState } from 'react';
import { ApiService } from '../services/Api';
import { Configuration } from '../typings/Configuration';
import { Profile } from '../typings/OIDCProfile';
import { UserData } from '../typings/UserData';
import { ApiServiceFoods } from 'Pack-manager/services/ApiFoods';

type AppContextData = {
  profile: Profile | undefined;
  accessToken: string | undefined;
  apiService: ApiService;
  settings: Configuration;
  apiServiceFoods: ApiServiceFoods;
};

export const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC<UserData> = ({ children, access_token, profile }) => {
  const [settings, setSettings] = useState({
    FacebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID as string,
    ApiServer: process.env.REACT_APP_API_SERVER as string,
    ApiPort: process.env.REACT_APP_API_PORT as string,
    BlipFoodsId: process.env.REACT_APP_BLIP_FOODS_ID as unknown as number,
    FacebookBusinessSystemUserToken: process.env.REACT_APP_FACEBOOK_BUSINESS_SYSTEM_USER_TOKEN as string,
    Environment: process.env.REACT_APP_ENV as 'staging' | 'production',
    MsgingServer: process.env.REACT_APP_MSGING_URL as string,
    BlipFoodsIdHmg: 402,
    ApiServerFoods: process.env.REACT_APP_API_FOODS as string,
    AdminTokenApi: process.env.REACT_APP_ADMIN_TOKEN_FOODS as string,
  });

  const [initialized, setInitialized] = useState(true);

  const apiService = useMemo(
    () => new ApiService(settings.ApiServer, settings.ApiPort, access_token),
    [access_token, settings.ApiPort, settings.ApiServer],
  );

  const apiServiceFoods = useMemo(() => new ApiServiceFoods(settings.ApiServerFoods), []);

  return initialized ? (
    <>
      <AppContext.Provider
        value={{
          profile,
          accessToken: access_token,
          apiService,
          settings,
          apiServiceFoods,
        }}
      >
        {children}
      </AppContext.Provider>
    </>
  ) : (
    <></>
  );
};

export function useAppContext(): AppContextData {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
