import { usePackContext } from '../../contexts/PackContext';
import React, { useEffect } from 'react';
import Logos from '../../assets';
import { FlexColumn } from '../../styles';
import { ModalAction, ModalHeader, TextBold, Text } from './styles';
import useTrack from '../../hooks/useTrack';
import { PG_BUTTON_ADD_PRODUCTS_MODAL } from '../../constants/TrackingEvents';

type TenantModalProps = {
  isOpen: boolean;
  onConfirm?: (isOk: boolean) => void;
};

const Modal: React.FC<TenantModalProps> = ({ isOpen, onConfirm: OnConfirmCallback }) => {
  const { setMerchantData, setActiveModule, modules } = usePackContext();
  const { tracking } = useTrack();

  const handleOnConfirm = () => {
    tracking(PG_BUTTON_ADD_PRODUCTS_MODAL);
    setActiveModule(modules[1]);
    setMerchantData({
      HasProduct: true,
    });
    OnConfirmCallback && OnConfirmCallback(false);
  };

  const handleCloseModal = () => {
    OnConfirmCallback && OnConfirmCallback(false);
  };

  const renderHeader = () => {
    return (
      <ModalHeader>
        <img src={Logos.Bad} style={{ height: '44px', width: '44px' }} alt="Blip Foods" />
        <TextBold>Ops, parece que houve um problema!</TextBold>
        <Text>Que tal começar adicionando seus produtos manualmente?</Text>
      </ModalHeader>
    );
  };

  return (
    <bds-modal open={isOpen} close-button="false" size="dynamic" onClick={handleCloseModal}>
      <FlexColumn>
        {renderHeader()}

        <ModalAction>
          <bds-button icon="add" variant="primary" style={{ marginTop: '40px' }} onClick={handleOnConfirm}>
            Adicionar produto
          </bds-button>
        </ModalAction>
      </FlexColumn>
    </bds-modal>
  );
};

export default Modal;
