export const RESOURCES_ROUTE = 'resources';
export const INSTALLATIONS_ROUTE = 'packs/installation';
export const GET_INSTALLATION_BY_SHORTNAME_ROUTE = 'installations/last';
export const TENANTS_ROUTE = 'tenants';
export const PLUGIN_ROUTE = 'configurations/application';
export const UPDATE_WPP_PROFILE_ROUTE = 'whatsapp/profile';
export const INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE = 'installations/channel-activated';
export const ACTIVATE_WHATSAPP_ROUTE = 'whatsapp/activate-number';
export const APPLICATION_ROUTE = 'packs';
export const INSTALLATION_BY_ID = 'installations';
export const BLIP_CHAT_CONFIGURATION = 'configurations/blip-chat';
