import { UPDATE_WPP_PROFILE_ROUTE } from '../../constants/ApiRoutes';
import { ModuleIds } from '../../constants/ModuleIds';
import { PG_WHATSAPP_UPDATE_PROFILE } from '../../constants/TrackingEvents';
import { useAppContext } from '../../contexts/AppContext';
import { usePackContext } from '../../contexts/PackContext';
import useTrack from '../../hooks/useTrack';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IsImageSizeAcceptable, UploadImage } from '../../services/ImageUpload';
import { createToast, createToastError } from '../../services/Toast';
import { HideOrShowContainer } from '../../styles';
import { ModuleComponents } from '../../typings/ModuleComponents';
import { handleApiErrorResponseForTrack, hideOrShowComponentInCss } from '../../utils';
import { Container, Grid, ImageRow, ImageUploadCircle } from './style';

const defaultImageUrl =
  'https://lh3.googleusercontent.com/u/0/drive-viewer/AFDK6gP8KshKAD4BiulyZhy0rCRZ4mR-n9sOEfqE1yS8VKqr2jW38vqBTCk5U5cSva5ptfosOWdOvBrqkMwjhnEPgcRUk2VyDQ=w2880-h1308';

const siteValidationRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

const WhatsAppConfiguration: FC<ModuleComponents> = ({ activeModule }) => {
  const { apiService, settings } = useAppContext();
  const { pack } = usePackContext();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<{ localUrl: string; serverUri: string }>();
  const [isValidForm, setIsValidForm] = useState(true);
  const siteInputRef = useRef<HTMLBdsInputElement>(null);
  const emailInputRef = useRef<HTMLBdsInputElement>(null);
  const addressInputRef = useRef<HTMLBdsInputElement>(null);
  const descriptionInputRef = useRef<HTMLBdsInputElement>(null);
  const hiddenImageInputRef = useRef<HTMLInputElement>(null);
  const { tracking } = useTrack();

  const updateInformations = async () => {
    setButtonLoading(true);
    try {
      await apiService.post(UPDATE_WPP_PROFILE_ROUTE, {
        shortName: pack.routerData.shortName,
        profile: {
          description: descriptionInputRef.current?.value,
          address: addressInputRef.current?.value,
          webSites: [siteInputRef.current?.value],
          email: emailInputRef.current?.value,
          photoUri: profileImage ? profileImage.serverUri : defaultImageUrl,
        },
      });
      tracking(PG_WHATSAPP_UPDATE_PROFILE, { status: 'Sucesso' });
      createToast({ toastTitle: 'Sucesso!', toastText: 'As informações do seu perfil foram atualizadas!' });
    } catch (error: any) {
      console.error(error);
      tracking(PG_WHATSAPP_UPDATE_PROFILE, { status: 'Falha', error: handleApiErrorResponseForTrack(error) });
      createToastError('Erro ao inserir informações do perfil', error.Message);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonLoading(true);
    if (event.target.files?.[0]) {
      try {
        if (!(await IsImageSizeAcceptable(event.target.files[0]))) {
          throw Error('A imagem precisa ser maior que 192 pixels em altura e largura.');
        }
        const imageUrl = await UploadImage(
          settings.MsgingServer,
          pack.routerData.shortName,
          pack.routerData.accessKey,
          event.target.files[0],
        );
        setProfileImage({ localUrl: URL.createObjectURL(event.target.files[0]), serverUri: imageUrl });
      } catch (err: any) {
        createToastError('Erro ao enviar imagem', err);
      } finally {
        event.target.value = '';
        setButtonLoading(false);
      }
    }
  };

  const showImageOrIcon = profileImage?.localUrl ? (
    <img src={profileImage.localUrl} />
  ) : (
    <bds-button-icon size="short" variant="ghost" icon="upload" />
  );

  const isValidUrl = (url: string) => {
    if (!url) {
      return true;
    }

    return siteValidationRegex.test(url);
  };

  useEffect(() => {
    const localRef = siteInputRef;
    localRef.current?.addEventListener('bdsOnBlur', (event: any) => {
      setIsValidForm(isValidUrl(event.target.value));
    });
    return () => {
      localRef.current?.removeEventListener('bdsOnBlur', (event: any) => isValidUrl(event.target.value));
    };
  }, []);

  return (
    <HideOrShowContainer display={hideOrShowComponentInCss(activeModule.id == ModuleIds.whatsAppProfile)}>
      <Container>
        <bds-typo variant="fs-20">
          Essas informações serão exibidas no seu perfil de contato do WhatsApp. Todos os seus clientes poderão
          visualiza-las.
        </bds-typo>
        <Grid>
          <div>
            <ImageRow>
              <ImageUploadCircle onClick={() => hiddenImageInputRef.current?.click()}>
                <div>
                  <input ref={hiddenImageInputRef} type="file" accept="image/*" onInput={handleUpload} />
                  {showImageOrIcon}
                </div>
              </ImageUploadCircle>
              <bds-typo>Imagem de perfil do WhatsApp</bds-typo>
            </ImageRow>
            <bds-input danger={!isValidForm} ref={siteInputRef} label="Site" />
            <bds-input ref={emailInputRef} label="E-mail da empresa" />
          </div>
          <div>
            <bds-input ref={addressInputRef} label="Endereço" />
            <bds-input ref={descriptionInputRef} label="Descrição" is-textarea rows={5} />
          </div>
        </Grid>
        <bds-button disabled={!isValidForm} bds-loading={buttonLoading} onClick={updateInformations}>
          Salvar
        </bds-button>
      </Container>
    </HideOrShowContainer>
  );
};

export default WhatsAppConfiguration;
